import React from 'react'
import { Carousel, Container, Row, Col } from 'react-bootstrap'
import Footer from './Footer'
import Abouthome from './Abouthome'

function Homepage() {
  const images = [
    { image: "https://dev7.sbagh.com/assets/banners/banner1.png" },
    { image: "/assets/allimages/2.JPG" },
    { image: "/assets/allimages/IMG_9033.JPG" },



  ]
  return (
    <>
      {/* <section id="hero" className="hero section dark-background">
        <Carousel controls={true}>
          {images.map((item, index) => {
            return (


              <Carousel.Item key={index} style={{ height: "400px" }}>
                <img
                  className="d-block w-100"
                  src={item.image}
                  alt="Slide image alt"
                  style={{ height: "100%", objectFit: "cover" }}
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x={50} y={3} />
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x={50} y={0} />
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x={50} y={9} />
          </g>
        </svg>
      </section> */}

      <section id="hero"   style={{ backgroundImage: `url('/assets/allimages/Gemini_Generated_Image_2yoswu2yoswu2yos.png')` }}
 className="hero section dark-background">
        <div
          id="hero-carousel"
          data-bs-interval={5000}
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
        >
          {/* Slide 1 */}
          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                Welcome to <span>Selecao</span>
              </h2>
              <p className="animate__animated animate__fadeInUp">
                Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est
                quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut.
                Similique ea voluptatem. Esse doloremque accusamus repellendus
                deleniti vel. Minus et tempore modi architecto.
              </p>
              <a
                href="#about"
                className="btn-get-started animate__animated animate__fadeInUp scrollto"
              >
                Read More
              </a>
            </div>
          </div>
          {/* Slide 2 */}
          <div className="carousel-item active">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                Lorem Ipsum Dolor
              </h2>
              <p className="animate__animated animate__fadeInUp">
                Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est
                quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut.
                Similique ea voluptatem. Esse doloremque accusamus repellendus
                deleniti vel. Minus et tempore modi architecto.
              </p>
              <a
                href="#about"
                className="btn-get-started animate__animated animate__fadeInUp scrollto"
              >
                Read More
              </a>
            </div>
          </div>
          {/* Slide 3 */}
          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                Sequi ea ut et est quaerat
              </h2>
              <p className="animate__animated animate__fadeInUp">
                Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est
                quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut.
                Similique ea voluptatem. Esse doloremque accusamus repellendus
                deleniti vel. Minus et tempore modi architecto.
              </p>
              <a
                href="#about"
                className="btn-get-started animate__animated animate__fadeInUp scrollto"
              >
                Read More
              </a>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#hero-carousel"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bi bi-chevron-left"
              aria-hidden="true"
            />
          </a>
          <a
            className="carousel-control-next"
            href="#hero-carousel"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bi bi-chevron-right"
              aria-hidden="true"
            />
          </a>
        </div>
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x={50} y={3} />
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x={50} y={0} />
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x={50} y={9} />
          </g>
        </svg>
      </section>

      <div className="swiper-slide-inner" data-swiper-parallax="50%">
        <div
          className="swiper-slide-inner-bg bg-img-1"
          style={{
            backgroundImage:
              "url(http://rox-uae.com/wp-content/uploads/2024/08/Rox-Motor-UAE-a7.jpg )"
          }}
        ></div>
        <div className="overlay overlay-dark-75" />
        <div className="swiper-slide-inner-txt"></div>
      </div>



      <div className=''>
        <Abouthome />
        <section id="services" className="services section">
          {/* Section Title */}
          <div className="container section-title" >
            <h2>Our Services</h2>

          </div>
          {/* End Section Title */}
          <div className="container">
            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6"

              >
                <div className="service-item  position-relative">
                  <div className="icon">
                    <i className="bi bi-car-front" />
                  </div>
                  <h3>Vehicle Yard</h3>
                  <p>
                    Transportation from Port to Freezone and to Pan Oman & UAE,Fueling for new vehicles,
                    Steering wheel Rapping and Protective seat covers,
                    RFID /Barcoding on vehicles.
                  </p>
                  <a href="/yard" className="readmore stretched-link">
                    Read more <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
              <div
                className="col-lg-4 col-md-6"

              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-house-gear" />
                  </div>
                  <h3 style={{ fontWeight: '600' }}>Warehouse</h3>
                  <p>
                    Based on the customer requirement we can do the customized packaging and labelling. We help client to achieve their goals and grow their business with our warehouse center.
                  </p>
                  <a href="/warehouse" className="readmore stretched-link">
                    Read more <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
              <div
                className="col-lg-4 col-md-6"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-bus-front" />
                  </div>
                  <h3>Transportation</h3>
                  <p>
                    Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea
                    aut. Vel qui id voluptas adipisci eos earum corrupti.
                  </p>
                  <a href="/transportation" className="readmore stretched-link">
                    Read more <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* <div
                className="col-lg-4 col-md-6"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-bounding-box-circles" />
                  </div>
                  <h3>Asperiores Commodit</h3>
                  <p>
                    Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate
                    sed error ea fuga sit provident adipisci neque.
                  </p>
                  <a href="service-details.html" className="readmore stretched-link">
                    Read more <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-calendar4-week" />
                  </div>
                  <h3>Velit Doloremque</h3>
                  <p>
                    Cumque et suscipit saepe. Est maiores autem enim facilis ut aut
                    ipsam corporis aut. Sed animi at autem alias eius labore.
                  </p>
                  <a href="service-details.html" className="readmore stretched-link">
                    Read more <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="bi bi-chat-square-text" />
                  </div>
                  <h3>Dolori Architecto</h3>
                  <p>
                    Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non
                    et debitis iure. Corrupti recusandae ducimus enim.
                  </p>
                  <a href="service-details.html" className="readmore stretched-link">
                    Read more <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <Footer />

      </div>
    </>
  )
}

export default Homepage
