import React from 'react'
import Footer from '../Footer'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
function Warehouse() {

    const service = [
        {
            id: 1, name: "Warehouse", links: [
                { name: 'Warehouse', link: '/warehouse' },
                { name: "Yard", link: '/yard' },
                { name: 'Transportation', link: '/transportation' },
                { name: 'Offered', link: '/offered' },


            ]
        }
    ]

    const images = [
        { image: "/assets/allimages/alpha_motor_warehouse_01.jpg" },
        { image: "/assets/allimages/alpha_motor_warehouse_02.jpg" },



    ]
    return (
        <>
            <Carousel>
                {images.map((item, index) => {
                    return (


                        <Carousel.Item key={index} style={{ height: "400px" }}>
                            <img
                                className="d-block w-100"
                                src={item.image}
                                alt="Slide image alt"
                                style={{ height: "100%", objectFit: "cover" }}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
            <Container className='mb-5'>
                <Row>
                    <div className="section-title-2" >
                        <h2>
                            Warehouse & Distribution Centre
                            Sohar Freezone - Oman          </h2>
                    </div>
                    <Col lg={7}>
                        <p>We offer a flexible and scalable warehouse solution that
                            can help you optimize your operation, reduce cost,
                            Increase efficiency, easy retrieval systems and On time
                            delivery to customer. We have a network of strategically
                            located warehouse across the country and a team of
                            experienced professionals who can handle any type of
                            product and delivery. Whether you need short-term or
                            long-term storage, local or international distribution.
                            Based on the customer requirement we can do the
                            customized packaging and labelling. We help client to
                            achieve their goals and grow their business with our
                            warehouse and distribution center.</p>
                            <div className="section-title-3" >
                            <h2 className='mt-5'>
                                Modern
                                infrastructure
                                facilities         </h2>
                        </div>
                        <div className='cheacks-div '>
                            <p><i class="bi bi-check"></i> 25 Hectare premise with 9 Hectare for Warehouse</p>
                            <p><i class="bi bi-check"></i> 12,500 Pallet positions, 240,000 Bins and open yard Storage</p>
                            <p><i class="bi bi-check"></i> Rack installed with Seismic Design for Zone 1</p>
                            <p><i class="bi bi-check"></i> Indoor 150C to 300C</p>
                            <p><i class="bi bi-check"></i> General lighting 300 Lux</p>
                            <p><i class="bi bi-check"></i> In-rack lighting 200 Lux</p>
                            <p><i class="bi bi-check"></i> Super flat floor, 12T per SQM Sturdiness</p>
                            <p><i class="bi bi-check"></i> Man reachable 4-tier multi-tier storage</p>
                            <p><i class="bi bi-check"></i> Indoor MHE’s like Reach trucks, Order Pickers, Pallet trucks and forklifts</p>
                            <p><i class="bi bi-check"></i> WiFi enabled SAP EWM Bar code operation</p>
                            <p><i class="bi bi-check"></i> 250+ CCTV surveillance camera with 24/7 Security personal</p>
                            <p><i class="bi bi-check"></i> In-Rack sprinkler & Firehose reels</p>
                            <p><i class="bi bi-check"></i> 9 cargo material lifts, 16 Dock levelers</p>
                            <p><i class="bi bi-check"></i> Fully furnished office space with kitchen & dining facility</p>
                            <p><i class="bi bi-check"></i> Storm water drain system each content add inside p tag</p>
                        </div>
                       


                    </Col>

                    <Col lg={5}>
                        <div>
                            <img src='/assets/allimages/Ware_House_03_450x300.jpg' width={'100%'} />
                            <div className='mt-4'>
                            <img src='/assets/allimages/Ware_House_04_450x300.jpg' width={'100%'} />
                        </div>
                       
                        </div>
                    </Col>
                    
                </Row>

                <Row className='mt-4'>
                    <Col lg={5} className='mt-4 cheack-tags'>
                    <div className=''>
                            <img src='/assets/allimages/Ware_House_01_450x300.jpg' width={'100%'} />
                        </div>
                        <div className='mt-4'>
                            <img src='/assets/allimages/Ware_House_02_450x300.jpg' width={'100%'} />
                        </div>

                    </Col>

                    <Col lg={7} className=''>
                    <div className="section-title-3 mt-4" >
                            <h2>
                                Our Service Offering        </h2>
                        </div>
                        <div className='cheacks-div'>
                        <p><i class="bi bi-check"></i> 67,500 Sq Mt Closed Dry storage Warehouse</p>
                        <p><i class="bi bi-check"></i> Multiple type of Product handling and storage</p>
                        <p><i class="bi bi-check"></i> System orientated process ERP - SAP eWMS</p>
                        <p><i class="bi bi-check"></i> Operation infrastructure with 30+ Types of MHE’s</p>
                        <p><i class="bi bi-check"></i> Cross docking with different types of 16 Dock levelers</p>
                        <p><i class="bi bi-check"></i> 12,500 Pallet positions, 240,000 Bins, and open yard Storage</p>
                        <p><i class="bi bi-check"></i> Tire, Battery, and Oil storage with FEFO & FIFO</p>
                        <p><i class="bi bi-check"></i> Multiple storage systems – Multitier storage units, Selective Pallet,High Racking, etc.</p>
                        <p><i class="bi bi-check"></i> Inbound, outbound, and stock Maintenance activities for all types of Products</p>
                        <p><i class="bi bi-check"></i> Custom clearance at Pan Oman, UAE & GCC</p>
                        <p><i class="bi bi-check"></i> Transportation from Port to Freezone, Pan Oman, and UAE</p>
                        <p><i class="bi bi-check"></i> Container Drayage, Container storage, and Container lashing & unlashing</p>
                        <p><i class="bi bi-check"></i> Last-Mile delivery with Tracking PDO</p>
                        <p><i class="bi bi-check"></i> Shipment consolidation and Reverse logistics</p>
                        <p><i class="bi bi-check"></i> Vendor and customer compliance</p>

                            </div>
                       
                    </Col>
                </Row>
              
            </Container>
            <Footer />

        </>
    )
}

export default Warehouse
