import React from 'react'
import Footer from '../Footer'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
function Transportation() {

    const service = [
        {
            id: 1, name: "Warehouse", links: [
                { name: 'Warehouse', link: '/warehouse' },
                { name: "Yard", link: '/yard' },
                { name: 'Transportation', link: '/transportation' },
                { name: 'Offered', link: '/offered' },


            ]
        }
    ]

    const images = [
        { image: "/assets/allimages/5.jpg" },
        { image: "/assets/allimages/IMG_9024.JPG" },
        { image: "/assets/allimages/IMG_9032.JPG" },
    ]
    return (
        <>
            <Carousel>
                {images.map((item, index) => {
                    return (


                        <Carousel.Item key={index} style={{ height: "400px" }}>
                            <img
                                className="d-block w-100"
                                src={item.image}
                                alt="Slide image alt"
                                style={{ height: "100%", objectFit: "cover" }}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
            <Container className='mb-5'>
                <Row>
                    <div className="section-title-2" >
                        <h2>
                            Transportation       </h2>
                    </div>
                    <Col lg={6} style={{ display: "grid", alignItems: "center" }}>
                        <div>
                            <p>Alpha Motors and Trading (FZC) LLC is fully equipped with specialized vehicles and machinery to handle all your material handling requirements efficiently.</p>
                            <p>Efficient warehouse and yard transportation services are crucial for industries managing heavy loads, large equipment, and complex supply chains. Having a well-rounded fleet of specialized vehicles and equipment ensures that your operations run smoothly and safely. Here's an overview of the critical transportation and material handling solutions available for warehouse and yard management.</p>
                        </div>

                    </Col>

                    <Col lg={6}>
                        <div>
                            <img src='/assets/allimages/IMG_9017_450x300.jpg' width={'100%'} />

                        </div>
                    </Col>



                    <Col lg={6} className='mt-5'>
                        <div>
                            <img src='/assets/allimages/Transpotation/car-carrier-truck-5209811.jpg.webp' width={'100%'} />
                      </div>
                    </Col>
                    <Col lg={6} style={{ display: "grid", alignItems: "center" }} className='mt-5'>
                        <div>
                            <h5>1. Maximizing Vehicle Transport Efficiency</h5>
                            <p>Transporting vehicles from point A to point B can be a challenging task, especially when handling multiple units at once. A diverse range of car carriers helps meet varying transportation demands:</p>

                            <div className='cheacks-div'>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 8-Car Carriers: Ideal for large-scale transport, moving up to eight cars at once, which is perfect for long-distance or high-volume deliveries.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 6-Car Carriers: Suited for medium-scale vehicle transport needs, providing a balance between capacity and maneuverability.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 3-Car Carriers: Best for smaller loads and shorter distances, offering quick and efficient service.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> Recovery Vehicles: Specialized for towing and transporting disabled or damaged vehicles, ensuring timely and secure recovery.</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} style={{ display: "grid", alignItems: "center" }} className='mt-5'>
                        <div>
                            <h5>2. Tailored for Spare Parts and Components</h5>
                            <p>For businesses involved in vehicle maintenance, parts delivery plays a vital role. Fleet options include:</p>

                            <div className='cheacks-div'>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 1-Ton, 3-Ton, 7-Ton, and 10-Ton Trucks: Scalable delivery options based on the size and volume of the parts to be delivered. From smaller, lighter loads with the 1-ton truck to more substantial deliveries with the 10-ton vehicle, flexibility is ensured.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> Flexi Containers: Ideal for delivering a variety of automotive parts that require protection from the elements, offering a secure solution for long-distance deliveries.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6} className='mt-5'>
                        <div>
                            <img src='/assets/allimages/Transpotation/foton truck.jpg' width={'100%'} />
                        </div>
                    </Col>

                    <Col lg={6} className='mt-5'>
                        <div>
                            <img src='/assets/allimages/Transpotation/1-8.jpeg' width={'100%'} />
                        </div>
                    </Col>

                    <Col lg={6} style={{ display: "grid", alignItems: "center" }} className='mt-5'>
                        <div>
                            <h5>3. Solutions for Large and Heavy Loads</h5>
                            <p>Moving heavy equipment and large cargo requires precision and strength. Specialized vehicles cater to various cargo movement needs:</p>
                            <div className='cheacks-div'>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> Multi-Axle Trailers: These trailers handle extremely heavy and oversized loads, offering high stability for industrial equipment transport.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 40’ Flat Bed Trailers: Versatile and capable of carrying a range of materials, flatbed trailers are perfect for transporting equipment, crates, or large pallets.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> Low Bed Trailers: Designed to carry oversized machinery, construction equipment, or heavy loads, ensuring safe and secure transport even on uneven terrain.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6} style={{ display: "grid", alignItems: "center" }} className='mt-5'>
                        <div >
                            <h5>4. Lifting Power for Heavy-Duty Jobs</h5>
                            <p>Cranes are essential in many industries, offering the power to lift heavy and cumbersome objects. With varying tonnage capabilities, the right crane can streamline operations and ensure safety:</p>

                            <div className='cheacks-div'>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 550-Ton RT Crane: For the heaviest loads, often used in large construction or industrial projects where maximum lifting capacity is essential.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 220-Ton and 110-Ton RT Cranes: Suitable for heavy loads, these cranes offer flexibility for moderate to large lifting tasks.</p>
                                <p style={{ display: "flex" }}><i class="bi bi-check"></i> 50-Ton and 25-Ton RT Cranes: Ideal for smaller lifting requirements, such as moving equipment or assisting in warehouse operations.</p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6} className='mt-5'>
                        <div>
                            <img src='/assets/allimages/Transpotation/550 Tone_crane.jpg' width={'100%'} />
                        </div>
                    </Col>


                    <Col lg={6} className='mt-5'>
                        <div>
                            <img src='/assets/allimages/Transpotation/Z9rDVZCwToFknnc47lPJSVoC6zZi9Yil1623700843.jpeg' width={'100%'} />
                        </div>
                    </Col>

                    <Col lg={6} className='mt-5'>
                        <h5>5. Forklifts for Handling Pallets and Heavy Items</h5>
                        <p>Forklifts are the backbone of most warehouse operations, providing the muscle needed for moving pallets, crates, and other heavy goods:</p>
                        <div className='cheacks-div'>
                            <p style={{ display: "flex" }}><i class="bi bi-check"></i> 3T Forklift: Perfect for handling standard pallets and smaller loads, this forklift is widely used in most warehouse environments.</p>
                            <p style={{ display: "flex" }}><i class="bi bi-check"></i> 10T Forklift: Suited for heavier loads, providing the strength needed for larger, bulkier items.</p>
                            <p style={{ display: "flex" }}><i class="bi bi-check"></i> 16T Forklift: Designed for handling extremely heavy materials, offering the maximum lifting capacity for demanding jobs.</p>
                        </div>
                        <h5 className='mt-4' >We provide Comprehensive Transportation Solutions</h5>
                        <p>By utilizing this wide range of specialized vehicles and equipment, your warehouse and yard transportation services can be optimized for efficiency, safety, and productivity. Whether moving vehicles, delivering parts, transporting large cargo, or lifting heavy equipment, having the right tools ensures that each task is handled with precision and care. From multi-axle trailers to high-capacity forklifts, investing in the proper fleet guarantees smooth and reliable operations for any industry.</p>
                    </Col>
                </Row>
            </Container>
            <Footer />

        </>
    )
}

export default Transportation
